






























































  import { GENDER } from '@/constant/Enums';
  import { Validator } from '@/constant/Mixins';
  import { ServerResponse } from '@/services/response.types';
  import { UserModel } from '@/store/auth/types';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'info',
    components: {},
    mixins: [],
  })
  export default class info extends Mixins(Validator) {
    @Action('GetProfile', { namespace: 'auth' }) GetProfile!: () => Promise<ServerResponse>;
    @Action('UpdateProfile', { namespace: 'auth' }) UpdateProfile!: (params?:any) => Promise<ServerResponse>;

    public GENDER = GENDER;
    @State('user', { namespace: 'auth' }) user!: UserModel;

    public created(): void {}

    public mounted(): void {
      this.GetProfile();
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      this.UpdateProfile(new FormData(ev.target as HTMLFormElement)).finally(()=>this.GetProfile())
    }
  }
